import getConfig from 'next/config';
import { IEnv } from './i-env';
import isBrowser from '../../utils/browser/isBrowser';

const { publicRuntimeConfig } = getConfig();
const {
  ENV,
  APP_ENV,
  LOGIN_URL,
  ADMIN_URL,
  HAVENLY_FEATURE_11_STAR_NAV,
  IS_CI,
  CI_APOLLO_URI,
  SERVER_APOLLO_URI,
  WEB_APOLLO_URI,
  E2E,
  SENTRY_DSN,
  SENTRY_ENABLED,
  SENTRY_RELEASE,
  AFFIRM_JS_URL,
  AFFIRM_PUBLIC_KEY,
  HELP_CENTER_URL,
  ANALYTICS_ENABLED,
  ANALYTICS_KEY,
  DOMAIN_NAME,
  PLATFORM_API_URL,
  TUSD_URL,
  LOGOUT_URL,
  THUMBOR_URL,
  STUDIO_UNITY_ROOT_URL,
  KUSTOMER_KEY,
  PRISMIC_CUSTOMER_UI_ACCESS_TOKEN,
  FACEBOOK_CLIENT_ID,
  OPEN_INSTALL_IOS_APP_DEEP_LINK,
  IOS_APP_URL_SCHEME_BASE,
  ONE_TRUST_ID,
  ONE_TRUST_USE_TEST_SCRIPT,
  YOTPO_CLIENT_ID,
  STRIPE_PUBLISHABLE_KEY,
  LOCAL_TO_DEV_REMOTE,
  HAVENLY_FEATURE_STUDIO_30,
  HAVENLY_FEATURE_STUDIO_30_RESIZE_PROPS_MODAL,
  HAVENLY_FEATURE_ORIGINAL_ROOM_FLOW_USES_CORE_3_DESIGN_EXPERIENCE,
  HAVENLY_FEATURE_NEW_PURCHASE_CONFIRMATION_PAGE,
  HAVENLY_STUDIO_BYOP,
  STATIC_PAGE_DEFAULT_CACHE_SECONDS,
  DD_CLIENT_TOKEN,
  HAVENLY_FEATURE_DEVICE_MODE_ANALYTICS_KEY,
  HAVENLY_FEATURE_ROOM_PROFILE_REDESIGN
} = publicRuntimeConfig;

// For some reason process.env and next only works with destructing, must be done this way
/* istanbul ignore next */
const env: IEnv = {
  ENV,
  APP_ENV,
  LOGIN_URL,
  ADMIN_URL,
  HAVENLY_FEATURE_11_STAR_NAV,
  IS_LOCAL: ENV === 'local',
  E2E: E2E === 'true',
  SENTRY_DSN,
  SENTRY_ENABLED: SENTRY_ENABLED === 'true',
  SENTRY_RELEASE,
  AFFIRM_JS_URL,
  AFFIRM_PUBLIC_KEY,
  HELP_CENTER_URL,
  ANALYTICS_ENABLED,
  ANALYTICS_KEY,
  DOMAIN_NAME,
  STUDIO_UNITY_ROOT_URL,
  YOTPO_CLIENT_ID,
  STRIPE_PUBLISHABLE_KEY,
  getApolloUrl: () => {
    let url;
    if (IS_CI === 'true') {
      url = CI_APOLLO_URI;
    } else if (!isBrowser()) {
      url = SERVER_APOLLO_URI;
    } else {
      url = WEB_APOLLO_URI;
    }
    return url;
  },
  PLATFORM_API_URL,
  TUSD_URL,
  LOGOUT_URL,
  THUMBOR_URL,
  KUSTOMER_KEY,
  PRISMIC_CUSTOMER_UI_ACCESS_TOKEN,
  FACEBOOK_CLIENT_ID,
  OPEN_INSTALL_IOS_APP_DEEP_LINK,
  IOS_APP_URL_SCHEME_BASE,
  ONE_TRUST_ID,
  ONE_TRUST_USE_TEST_SCRIPT: ONE_TRUST_USE_TEST_SCRIPT !== 'false',
  LOCAL_TO_DEV_REMOTE: LOCAL_TO_DEV_REMOTE === 'true',
  HAVENLY_FEATURE_STUDIO_30: HAVENLY_FEATURE_STUDIO_30 === 'true',
  HAVENLY_FEATURE_STUDIO_30_RESIZE_PROPS_MODAL: HAVENLY_FEATURE_STUDIO_30_RESIZE_PROPS_MODAL === 'true',
  INSTAGRAM_ACCESS_CODE: process.env.INSTAGRAM_ACCESS_CODE,
  INSTAGRAM_GRAPH_API_URL: process.env.INSTAGRAM_GRAPH_API_URL,
  HAVENLY_FEATURE_NEW_CONTACT_US_FORM: process.env.HAVENLY_FEATURE_NEW_CONTACT_US_FORM === 'true',
  HAVENLY_FEATURE_ORIGINAL_ROOM_FLOW_USES_CORE_3_DESIGN_EXPERIENCE: HAVENLY_FEATURE_ORIGINAL_ROOM_FLOW_USES_CORE_3_DESIGN_EXPERIENCE === 'true',
  HAVENLY_FEATURE_NEW_PURCHASE_CONFIRMATION_PAGE: HAVENLY_FEATURE_NEW_PURCHASE_CONFIRMATION_PAGE === 'true',
  HAVENLY_STUDIO_BYOP: HAVENLY_STUDIO_BYOP === 'true',
  STATIC_PAGE_DEFAULT_CACHE_SECONDS: parseInt(STATIC_PAGE_DEFAULT_CACHE_SECONDS, 10),
  DD_CLIENT_TOKEN: DD_CLIENT_TOKEN!,
  HAVENLY_FEATURE_DEVICE_MODE_ANALYTICS_KEY,
  HAVENLY_FEATURE_ROOM_PROFILE_REDESIGN: HAVENLY_FEATURE_ROOM_PROFILE_REDESIGN === 'true'
};

export default env;
