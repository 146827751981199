import Cookie from 'js-cookie';

import { HAVENLY_ANONYMOUS_ID_COOKIE } from '@lib/cookie/havenlyAnonymousId';
import TrackingEvents from '@constants/tracking/events';
import { convertProps } from './converters';
import env from '../envs/env';

/**
 * Records an action that was performed, along with
 * any properties that describe the action.
 *
 * @param event Name of the event that a user has performed.
 * @param properties Free-form dictionary of properties for this event.
 */
const analyticsTrack = <T = { [key: string]: any }> (
  event: string | TrackingEvents,
  properties?: T,
) => {
  if (!env.ANALYTICS_ENABLED || !window || !window.analytics || !window.analytics.track) {
    return;
  }

  const convertedProps = convertProps(properties);

  window.analytics.track(
    event,
    convertedProps,
    {
      havenly_anonymous_id: Cookie.get(HAVENLY_ANONYMOUS_ID_COOKIE),
      integrations: {
        All: true,
        AppBoy: false,
      },
    },
  );
};
export default analyticsTrack;
